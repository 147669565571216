import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const urlParams = new URLSearchParams(window.location.search);
const pubid = urlParams.get('pubid');
const txid = urlParams.get('txid');
const mkId = urlParams.get('mkId');
const prodid = urlParams.get('prodid');


if (pubid) {
  sessionStorage.setItem('pubid', pubid);
}
if (txid) {
  sessionStorage.setItem('txid', txid);
}
if (mkId) {
  sessionStorage.setItem('mkId', mkId);
}
if (prodid) {
  sessionStorage.setItem('prodid', prodid)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();