import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { baseUrl } from "../util/CommonUtil";
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/games.css';


export default function Games() {
    const [category, setCategory] = useState("ALL GAMES");
    const [cardData, setCardData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [open, setOpen] = useState(false);
    const [msisdn, setMsisdn] = useState('');
    const [url, setUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {

        //check if msisdn is in sesssion
        const storedMsisdn = sessionStorage.getItem('msisdn');
        if (storedMsisdn) {
            setMsisdn(storedMsisdn);
        }

        // Fetch the card data from the API endpoint
        fetch('https://gamesapi.gamepoa.com/api/game/all-active')
            .then(response => response.json())
            .then(data => {
                setCardData(data);
                setFilteredData(data);
            })
            .catch(error => {
                console.error('Error fetching card data:', error);
                // Handle the error if needed
            });
    }, []);


    const handleCheckSubscription = () => {

        setIsLoading(true);
        const msisdn = sessionStorage.getItem('msisdn');
        const requestBody = {
            subscriberId: `254${msisdn.slice(-9)}`,
            productId: "280c0a91-fbca-429f-b8e9-b308bc0ee4e9",
        };

        fetch(`${baseUrl}/api/checkSubscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Response from server:', data);
                if (data.status === "SUCCESS") {
                    // Display success message
                    console.log('Subscription is already active');
                    const url = sessionStorage.getItem('url'); // Retrieve the URL from sessionStorage
                    window.location.href = url;
                }
                else if (data.status === "FAILURE" || "SUCCESS") {
                    if (sessionStorage.getItem('clickId')) {
                        // Click ID exists in session storage, retrieve it
                        var clickId = sessionStorage.getItem('clickId');
                    } else {
                        // Click ID doesn't exist in session storage, use a default one
                        var clickId = "f7881efb-033b-495b-a6dd-f8a02be00b09";
                    }
                    // Remove msisdn from sessionStorage
                    sessionStorage.removeItem('msisdn');
                    // Remove url from sessionStorage
                    sessionStorage.removeItem('url');
                    // call the request/subscribe endpoint
                    return fetch('http://51.158.100.191:9158/api/wap/send-sub', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ subscriberId: `254${msisdn.slice(-9)}`, productId: "280c0a91-fbca-429f-b8e9-b308bc0ee4e9", txId: sessionStorage.getItem('txid'), pubId: sessionStorage.getItem('pubid'), marketerId: sessionStorage.getItem('mkId') })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(subscribeData => {
                            console.log('Response from subscribe endpoint:', subscribeData);


                            if (subscribeData.message === "SUBSCRIBED") {
                                // Call the charge endpoint to renew subscription
                                return fetch('http://51.158.100.191:9158/api/wap/send-charge', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ subscriberId: `254${msisdn.slice(-9)}`, productId: "280c0a91-fbca-429f-b8e9-b308bc0ee4e9", amount: "10.0" })
                                })
                                    .then(response => {
                                        if (!response.ok) {
                                            throw new Error('Network response was not ok');
                                        }
                                        return response.json();
                                    })
                                    .then(chargeData => {
                                        console.log('Response from charge endpoint:', chargeData);
                                        if (chargeData.status === "SUCCESS") {
                                            // Store subscriberId in localStorage
                                            console.log('Subscription is already active');
                                            alert('Billing is underway. Please wait.'); // Show alert to the user
                                            setTimeout(() => {
                                                window.location.href = '/'; // Navigate to "/page" after 3 seconds
                                            }, 1000);
                                        }
                                    });
                            } else if (subscribeData.redirectUrl !== null) {
                                const refId = subscribeData.refId; // Extract the refId from subscribeData

                                window.location.href = subscribeData.redirectUrl;
                            }
                        });
                }
            })
            .then(response => {
                if (response && !response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response?.json();
            })
            .catch(error => {
                console.error('Error calling endpoint:', error);
                // handle the error if needed
            });
    };
    const handleOpen = (url) => {

        const storedMsisdn = sessionStorage.getItem('msisdn');
        sessionStorage.setItem('url', url);

        if (storedMsisdn) {
            setUrl(url);
            window.location.href = url;
        } else {
            setOpen(true);

        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleFilterClick = (selcategory) => {
        setCategory(selcategory);

        if (selcategory == "ALL GAMES") {
            setFilteredData(cardData);
        } else {
            setFilteredData(cardData.filter(card => card.category == selcategory));
        }
    };

    const categories = ["ALL GAMES", "HOME", ...new Set(cardData.map((data) => data.category))];
    const activeCategory = category; // Assign the currently selected category to activeCategory

    const groupedData = {};
    cardData.forEach((data) => {
        if (!groupedData[data.category]) {
            groupedData[data.category] = [];
        }
        groupedData[data.category].push(data);
    });

    return (
        <Grid container spacing={0.5}>
            <Box
                sx={{
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                    marginLeft: "30px",
                    mt: 1.5,
                    "@media screen and (max-width: 600px)": {
                        marginLeft: 0,
                        justifyContent: "flex-start",
                        padding: "0 10px",
                        overflowX: "scroll",
                    },
                }}
            >

                {categories.map((category) => (
                    <Button
                        key={category}
                        onClick={() => handleFilterClick(category)}
                        color="success"
                        variant={category === activeCategory ? "contained" : "outlined"}
                        sx={{
                            marginRight: "20px",
                            marginLeft: "10px",
                            marginBottom: "10px",
                            px: "40px",
                            borderRadius: "30px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            fontFamily: "sans-serif",
                        }}
                    >
                        {category}
                    </Button>
                ))}
            </Box>

            <Grid
                container
                spacing={0.5}
                sx={{
                    marginLeft: { xs: '10px', sm: '10px', md: '30px' },
                    marginRight: { xs: '10px', sm: '10px', md: '30px' },
                    overflowX: "hidden",
                }}
            >


                {category === "HOME" ? (
                    Object.entries(groupedData).map(([category, data]) => (
                        <React.Fragment key={category}>
                            <Typography variant="h5" sx={{ marginTop: '10px', marginLeft: '20px', fontSize: "15px", fontWeight: "bold", fontFamily: "sans-serif", }}>
                                {category}
                            </Typography>

                            <Grid
                                container
                                spacing={2.5}
                                sx={{
                                    flexWrap: 'nowrap',
                                    marginTop: '5px',
                                    overflowX: 'auto',
                                    marginLeft: '10px', // Add a negative margin to show the left border
                                }}
                            >

                                {data.map((game) => (
                                    <Card
                                        sx={{
                                            borderRadius: "10px",
                                            boxShadow: '0px 2px 6px black',
                                            margin: '10px',
                                            flexShrink: 0,
                                            width: { xs: 150, sm: 300, md: 300 },
                                            height: { xs: "auto", sm: "300px", md: "300px" },
                                            overflow: "hidden",
                                        }}
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    height: { xs: "100px", sm: "150px", md: "150px" },
                                                    width: "100%",
                                                }}
                                                image={game.banner}
                                                alt={game.name}
                                                onClick={() => handleOpen(game.url)}
                                            />
                                            <CardContent sx={{ height: '70px' }}>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                    sx={{
                                                        textAlign: "center",
                                                        fontSize: "0.75rem",
                                                        fontWeight: "500",
                                                        fontFamily: "RooneySans, Helvetica, Arial, sans-serif"
                                                    }}
                                                >
                                                    {game.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        textAlign: "center",
                                                        maxWidth: "100%",
                                                        fontFamily: "RooneySans, Helvetica, Arial, sans-serif"
                                                    }}
                                                >
                                                    {game.description.length > 20 ? (
                                                        <Tooltip title={game.description} placement="top">
                                                            <span>
                                                                {game.description.substring(0, 30)}...
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        game.description
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => handleOpen(game.url)}
                                            >
                                                PLAY
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                            </Grid>


                        </React.Fragment>
                    ))
                ) : (
                    <React.Fragment>

                        <Grid
                            container
                            spacing={2.5}
                            sx={{
                                marginTop: '5px',

                                marginLeft: '10px', // Add a negative margin to show the left border
                            }}
                        >

                            {

                                filteredData.map((game) => (
                                    <Card
                                        sx={{
                                            borderRadius: "10px",
                                            boxShadow: '0px 2px 6px black',
                                            margin: '10px',
                                            flexShrink: 0,
                                            width: { xs: 150, sm: 260, md: 260 },
                                            height: { xs: "auto", sm: "300px", md: "300px" },
                                            overflow: "hidden",
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    height: { xs: "100px", sm: "150px", md: "150px" },
                                                    width: "100%",
                                                }}
                                                image={game.banner}
                                                alt={game.name}
                                                onClick={() => handleOpen(game.url)}
                                            />
                                            <CardContent sx={{ height: '70px' }}>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="div"
                                                    sx={{
                                                        textAlign: "center",
                                                        fontSize: "0.75rem",
                                                        fontWeight: "500",
                                                        fontFamily: "RooneySans, Helvetica, Arial, sans-serif"
                                                    }}
                                                >
                                                    {game.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        textAlign: "center",
                                                        maxWidth: "100%",
                                                        fontFamily: "RooneySans, Helvetica, Arial, sans-serif"
                                                    }}
                                                >
                                                    {game.description.length > 20 ? (
                                                        <Tooltip title={game.description} placement="top">
                                                            <span>
                                                                {game.description.substring(0, 30)}...
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        game.description
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => handleOpen(game.url)}
                                            >
                                                PLAY
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                        </Grid>


                    </React.Fragment>
                )

                }
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 300,
                            height: 200,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: "10px",

                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography id="modal-title" variant="h5" component="h2" gutterBottom>
                            Enter Your Phone Number
                        </Typography>
                        <TextField
                            fullWidth
                            type="number"
                            label="Phone Number"
                            sx={{ mb: 1 }}
                            value={msisdn}
                            onChange={(event) => {
                                const msisdn = event.target.value;
                                setMsisdn(msisdn);
                                sessionStorage.setItem('msisdn', msisdn);
                            }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleCheckSubscription}
                                disabled={isLoading || msisdn.length <= 9}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'SUBMIT'}
                            </Button>

                        </Box>
                    </Box>
                </Modal>
            </Grid>
        </Grid>
    );
}